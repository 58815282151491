<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="header">
        <div class="header_box">
          <div class="img_out">
            <img :src="get_user_head(origin.relationship, origin.gender)" width="51" alt="" />
          </div>
          <div class="header_name">{{origin.relationship_label}}</div>
        </div>
        <div class="switch_btn" @click="showPopup">
          <div>切换</div>
          <img src="../../../assets/img/switch-btn.png" alt="" width="12" />
        </div>
      </div>
      <van-skeleton title :row="11" :loading="loading">
      <div>
      <div class="info_box">
        <div class="info_title">家庭基本信息</div>
        <div class="info_tabs">
          <div class="info_left">真实姓名</div>
          <div class="info_right">{{origin.name}}</div>
        </div>
        <div class="info_tabs">
          <div class="info_left">身份</div>
          <div class="info_right">{{origin.relationship_label}}</div>
        </div>
        <div class="info_tabs">
          <div class="info_left">手机号码</div>
          <div class="info_right">{{origin.phone}}</div>
        </div>
      </div>

      <div class="info_box">
        <div class="info_title">就诊信息</div>
        <div class="info_tabs">
          <div class="info_left">证件号</div>
          <div class="info_right">{{origin.id_no}}</div>
        </div>
        <div class="info_tabs">
          <div class="info_left">出生日期</div>
          <div class="info_right">{{format_date(origin.birthday)}}</div>
        </div>
        <div class="info_tabs">
          <div class="info_left">健康卡</div>
          <div class="info_right">{{format_medical_card(origin.medical_card_no)}}</div>
        </div>
      </div>
      </div>
</van-skeleton>
      <div class="delete_btn" @click="showDeletePop" v-if="origin.relationship !==1">删除此人</div>

      <!-- <div class="add_btn">添加家人</div> -->

      <van-popup
        v-model="showPop"
        round
        position="bottom"
        :style="{ minHeight: '30%', maxHeight: '80%' }"
      >
        <div class="family_box">
          <div class="family_title">切换家人</div>
          <div class="family_card" v-for="(item, idx) in data.members" :key="idx">
            <div class="family_head">
              <div class="img_out">
                <img :src="get_user_head(origin.relationship, origin.gender)" alt="" width="41" />
              </div>
              <div>{{item.relationship_label}}</div>
            </div>

            <div class="family_info">
              <p class="family_name">{{item.name}}</p>
              <p>健康卡: {{format_medical_card(item.medical_card_no)}}</p>
            </div>
            <div class="family_btn" @click="chooseFamily(item.medical_card_no)">选择</div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="showDelete" round position="bottom" :style="{ minHeight: '20%'}">
        <div class="delete_box">
          <p class="delete_title">删除后不会删除历史数据，下次绑定后依然可以使用该账户</p>
          <div class="delete_confirm" @click="confirm_delete">删除此人</div>
          <div class="delete_cancel" @click="closeDeletePop">取消</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { family_info, set_default_relation, delete_family, get_medical_cards_defaults,} from './service'
import { format_date, get_url_params, format_medical_card } from '../../../utils/format'
import { get_user_head } from '../../../utils/imgUrl'
import {setSession, getSession} from '../../../utils/session'


export default {
  data() {
    return {
      data: {},
      origin:[],
      showPop: false,
      showDelete: false,
      loading: false,
      rs_id: 0
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.get_person_info()
    this.rs_id = get_url_params().medical_card_no;
  },
  methods: {
    format_date,
    get_url_params,
    format_medical_card,
    get_user_head,
    showPopup() {
      this.showPop = true;
    },
    showDeletePop() {
      this.showDelete = true;
    },
    closeDeletePop() {
      this.showDelete = false;
    },
    async get_person_info() {
      this.loading = true;
      try {
        const {data} = await family_info()
        this.data = data
        this.get_current()
      } finally {
        this.loading = false;
      }
    },
    get_current() {
      let idx = 0;
      let length = this.data.members.length
      if (!this.rs_id) {
        for (let i = 0; i < length; i++) {
          if (this.data.members[i].default_member) {
            idx = i
            break
          }
        }
      } else {
        for (let i = 0; i < length; i++) {
          if (this.data.members[i].medical_card_no === this.rs_id) {
            idx = i
            break
          }
        }
      }
      this.origin = this.data.members.splice(idx, 1)[0]
    },
    async chooseFamily(id) {
      this.loading = true;
      try {
        await set_default_relation({medical_card_no: id})
        this.$router.push({
          path: '/',
        })
      } finally {
        this.loading = false;
      }
    },
    async confirm_delete() {
      this.loading = true;
      try {
        await delete_family(this.origin.medical_card_no)
        this.medical_cards_defaults()
      } finally {
        this.loading = false;
      }
    },
    async medical_cards_defaults () {
      this.loading = true
      let auth = getSession()
      try {
        const {data} = await get_medical_cards_defaults()
        if (data && data.name) {
          auth.has_card = true
          setSession(auth)
        } else {
          auth.has_card = false
          setSession(auth)
        }
        this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },

  },
};
</script>

<style scoped>
.g_page_box {
  background: #f7f7f7;
}

.header {
  height: 108px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaeaea;
  position: relative;
}

.header_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img_out {
  width: 55px;
  height: 55px;
  background: #EDF5FF;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 28px;
  text-align: center;
}

.switch_btn {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: #0088ff;
  display: flex;
  align-items: center;
  right: 16px;

  & img {
    margin-left: 6px;
  }
}

.header_name {
  width: 43px;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  background: #ffa700;
  opacity: 1;
  border-radius: 9px;
  text-align: center;
  margin-top: -5px;
}

.info_box {
  font-size: 14px;
  line-height: 40px;
  border-bottom: solid 1px #eaeaea;

  & .info_title {
    color: #9a9a9a;
    padding-left: 23px;
  }

  & .info_tabs {
    color: #242938;
    background: #ffffff;
    padding-left: 23px;
    display: flex;
    position: relative;
    border-top: solid 1px #eaeaea;
  }

  & .info_left {
    min-width: 107px;
  }
}

.delete_btn {
  text-align: center;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 24px;
  color: #0088ff;
}

.add_btn {
  position: fixed;
  left: 35px;
  right: 35px;
  bottom: 26px;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: #ffffff;
  height: 40px;
  background: #0088ff;
  opacity: 1;
  border-radius: 6px;
  text-align: center;
}

.family_box {
  padding: 25px;
}

.family_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #242938;
}

.family_card {
  margin-top: 24px;
  padding-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.family_head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #ffffff;
  margin-right: 20px;

  & div {
    width: 43px;
    height: 17px;
    background: #ffa700;
    opacity: 1;
    border-radius: 9px;
    text-align: center;
    margin-top: -2px;
  }

  & .img_out {
    width: 45px;
    height: 45px;
    background: #EDF5FF;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 28px;
    text-align: center;
    margin-top: 0;
  }
}

.family_info {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #707070;
  width: 150px;
}

.family_name {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #242938;
}

.family_btn {
  min-width: 66px;
  height: 30px;
  background: #ff7823;
  box-shadow: 0px 2px 8px rgba(255, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  padding: 0 5px;
}

.delete_box {
  padding: 20px 0;
}

.delete_title {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #707070;
  padding: 0 25px 20px;
  border-bottom: 1px solid #E8E8E8;
}

.delete_confirm,
.delete_cancel {
  padding: 16px 0;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  color: #FF4444;
  border-bottom: solid 6px #F7F7F7;
}

.delete_cancel {
  border: none;
  color: #242938;
}
</style>
