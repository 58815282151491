// 图片地址
export function getImgURL(url) {
  if (!/^http/g.test(url) && !/^data:image/g.test(url)) {
    url = `${GLOBAL_CONFIG.backend_server}/api/v1/common/image/${url}`
  }
  return url
}

// 头像
export function get_user_head(type, gender) {
  if (!type) {
    return './img/family-imgs/user-header-2.png'
  }
  if (type === 1 || type === 2) {
    if (gender === 1) {
      return './img/family-imgs/user-header-2.png'
    } else {
      return './img/family-imgs/user-header-1.png'
    }
  }
  if (type === 5) {
    if (gender === 1) {
      return './img/family-imgs/user-header-5.png'
    } else {
      return './img/family-imgs/user-header-6.png'
    }
  }
  return `./img/family-imgs/user-header-${type}.png`
}

// 医生头像
export function doctor_head_img(url) {
  if (!url) {
    return './img/doc_defalut_male.jpg'
  }
  if (!/^http/g.test(url) && !/^data:image/g.test(url)) {
    return `https://annetinfo1.oss-cn-shenzhen.aliyuncs.com/${url}`
  }
  return url
}
