import api from '../../../utils/api'

// 授权
export async function family_info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         members: [

  //           {
  //             name: '小王',
  //             relationship: 2,
  //             relationship_label: '配偶',
  //             phone: '13990940596',
  //             id_no: '794729428595435345',
  //             birthday: 632769600,
  //             medical_card_no: '33454433556',
  //             relationship_id: 3
  //           },
  //           {
  //             name: '大李',
  //             relationship: 4,
  //             relationship_label: '父母',
  //             phone: '13990940596',
  //             id_no: '794729428595435345',
  //             birthday: 1617600,
  //             medical_card_no: '33454433556',
  //             relationship_id: 4
  //           },
  //           {
  //             name: '李明',
  //             relationship: 3,
  //             relationship_label: '本人',
  //             phone: '13990940596',
  //             id_no: '794729428595435345',
  //             birthday: 1617601581,
  //             medical_card_no: '33454433556',
  //             relationship_id: 1
  //           },
  //           {
  //             name: '小李',
  //             relationship: 1,
  //             relationship_label: '子女',
  //             phone: '13990940596',
  //             id_no: '794729428595435345',
  //             birthday: 1421688000,
  //             medical_card_no: '33454433556',
  //             relationship_id: 2
  //           },
  //           {
  //             name: '不认识',
  //             relationship: 5,
  //             relationship_label: '其他',
  //             phone: '13990940596',
  //             id_no: '794729428595435345',
  //             birthday: 790536000,
  //             medical_card_no: '33454433556',
  //             relationship_id: 5
  //           }
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/families/relationships`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships`,payload)
}

export async function set_default_relation (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         result: true
  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/families/relationships/defaults`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships/defaults`,payload)
}

export async function delete_family (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         result: true
  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/families/relationships/${payload}/delete`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships/${payload}`)
}

export async function get_medical_cards_defaults () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: '李明',
  //         qr_code: ''
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/medical_cards/defaults`, )
}
